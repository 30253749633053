import React from 'react';
import {Box, Button} from '@chakra-ui/react';
import {OAuthProvider} from 'firebase/auth';
import {FirebaseError} from 'firebase/app';
import {useDpErrorToast} from 'dp-chakra/src/hooks/use-dp-toast';
import {useSignInWithRedirect} from '../../../packages/firebase-core/src/hooks/use-sign-in-with-redirect';

export const SignInWithMedicalTribuneButton = () => {
  const dpErrorToast = useDpErrorToast();

  const {mutate: signInWithRedirectMutation} = useSignInWithRedirect({
    onError(error) {
      if (error instanceof FirebaseError) {
        dpErrorToast({
          title: error.message,
        });
      } else {
        dpErrorToast({
          title: 'MTログインに失敗しました。',
        });
      }
    },
  });

  const handleClick = () => {
    const providerId = process.env.NEXT_PUBLIC_MT_PROVIDER_ID;
    if (!providerId) {
      return;
    }

    const provider = new OAuthProvider(providerId);
    provider.addScope('email');
    provider.addScope('openid');
    provider.addScope('profile');

    signInWithRedirectMutation({provider});
  };

  return (
    <Button
      variant="outline"
      height="44px"
      borderColor="blueGray.500"
      leftIcon={
        <img src="/images/mt-logo.png" alt="MT" width="32px" height="32px" />
      }
      px={1.5}
      onClick={handleClick}
    >
      <Box w="100%" ml="-32px">
        MT-MEET IDで続ける
      </Box>
    </Button>
  );
};
